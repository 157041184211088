<script setup>
import {
    BookmarkIcon,
    CloudArrowDownIcon,
    HomeIcon,
    MagnifyingGlassIcon,
    UserCircleIcon
} from '@heroicons/vue/24/outline'
import {Link, usePage} from "@inertiajs/vue3";
import {useWpaInstallStore} from "@/Stores/WpaInstallStore";

const pwaInstallStore = useWpaInstallStore();

</script>

<template>
    <div class="w-full h-14 fixed z-[9999] bottom-0 bg-dark-900 exclude-poppit">
        <nav :class="{
            'grid-cols-4': !pwaInstallStore.enabled,
            'grid-cols-5': pwaInstallStore.enabled
        }" class="max-w-xl mx-auto grid grid-cols-5">
            <Link :class="{'animate-selected' : route().current('homepage')}" :href="route('homepage')"
                  aria-label="Homepage"
                  class="p-4 w-full h-full flex items-center justify-center text-dark-300 ">
                <HomeIcon class="h-8"/>
            </Link>

            <Link :class="{'animate-selected' : route().current('discover')}" :href="route('discover')"
                  aria-label="Discover Posts and Creators"
                  class="p-4 w-full h-full flex items-center justify-center text-dark-300 ">
                <MagnifyingGlassIcon class="h-8"/>
            </Link>

            <button v-if="pwaInstallStore.enabled" aria-label="Download App"
                    class=" p-4 w-full h-full flex items-center justify-center text-dark-300 "
                    @click="pwaInstallStore.showInAppInstallPromotion('Menu Bounce - Green')">
                <CloudArrowDownIcon class="h-8 text-green-600 animate-bounce"/>
            </button>

            <Link :class="{'animate-selected' : route().current('collection.index')}" :href="route('collection.index')"
                  aria-label="Collections"
                  class="p-4 w-full h-full flex items-center justify-center text-dark-300 ">
                <BookmarkIcon class="h-8"/>
            </Link>

            <Link v-if="usePage().props.auth.user" :class="{'animate-selected' : route().current('profile.index')}"
                  :href="route('profile.index')" aria-label="My Account"
                  class="p-4 w-full h-full flex items-center justify-center text-dark-300 ">
                <UserCircleIcon class="h-8"/>
            </Link>
            <Link v-else :class="{'animate-selected' : route().current('login')}" :href="route('login')"
                  aria-label="Login/Register"
                  class="p-4 w-full h-full flex items-center justify-center text-dark-300">
                <UserCircleIcon class="h-8"/>
            </Link>
        </nav>
    </div>
</template>
